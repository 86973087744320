<template>
  <!-- 1是隐私协议  2是用户协议 -->
  <div class="UserAgreement" style="height:100%">
            <iframe v-if="$route.query.type==1" src="../../../../static/pdf/指南针智填隐私协议 -钧信教育.pdf" width="100%" height="100%" frameborder="0"></iframe>
            <iframe v-if="$route.query.type==2" src="../../../../static/pdf/指南针智填用户协议- 钧信教育.pdf" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: 'UserAgreement',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="scss"></style>
